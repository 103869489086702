import Key, { MajorKey, MinorKey } from "@tonaljs/key";

export const majorKeys: Array<MajorKey> = [
  Key.majorKey("C"),
  Key.majorKey("G"),
  Key.majorKey("D"),
  Key.majorKey("A"),
  Key.majorKey("E"),
  Key.majorKey("B"),
  Key.majorKey("F#"),
  Key.majorKey("C#"),
  Key.majorKey("Cb"),
  Key.majorKey("Gb"),
  Key.majorKey("Db"),
  Key.majorKey("Ab"),
  Key.majorKey("Eb"),
  Key.majorKey("Bb"),
  Key.majorKey("F"),
];

export const minorKeys: Array<MinorKey> = [
  Key.minorKey("A"),
  Key.minorKey("E"),
  Key.minorKey("B"),
  Key.minorKey("F#"),
  Key.minorKey("C#"),
  Key.minorKey("G#"),
  Key.minorKey("D#"),
  Key.minorKey("Eb"),
  Key.minorKey("Bb"),
  Key.minorKey("F"),
  Key.minorKey("C"),
  Key.minorKey("G"),
  Key.minorKey("D"),
];
