import { Center, Text } from "@chakra-ui/react";
import { Key } from "@tonaljs/key";
import { Clef } from "abcjs";
import { Score } from "../../ui/Score";

export interface RelativeKeyMessageProps {
  keySignature: Key;
  relativeKeySignature: Key;
  clef: Clef;
}

export function RelativeKeyMessage({
  keySignature,
  relativeKeySignature: relKeySignature,
  clef,
}: RelativeKeyMessageProps) {
  return (
    <>
      <Text>
        The relative <strong>{relKeySignature.type}</strong>
        {" of "}
        <strong>
          {keySignature.tonic} {keySignature.type}
        </strong>
        {" is "}
        <strong>
          {relKeySignature.tonic} {relKeySignature.type}!
        </strong>
        {` Here's that same key signature represented using the ${
          clef === "bass" ? "treble" : "bass"
        } clef:`}
      </Text>

      <Center mt={4}>
        <Score
          clef={clef === "bass" ? "treble" : "bass"}
          keySignature={relKeySignature}
        />
      </Center>
    </>
  );
}
