import { SlideFade } from "@chakra-ui/react";
import { ReactNode } from "react";
import { PuzzleType } from "../../common/types";
import { usePuzzle } from "../../contexts/PuzzleContext";
import { NavBar } from "../sections/NavBar";
import { KeySignaturePuzzle } from "./KeySignaturePuzzle/KeySignaturePuzzle";

type PuzzleDictionary = {
  [K in PuzzleType]: ReactNode;
};

const puzzles: PuzzleDictionary = {
  KeySignature: <KeySignaturePuzzle />,
};

export function PuzzlesPage() {
  const { puzzleType, puzzleNumber } = usePuzzle();

  return (
    <>
      <NavBar />

      <SlideFade in={true} offsetY="20px" key={`${puzzleNumber}`}>
        {puzzles[puzzleType]}
      </SlideFade>
    </>
  );
}
