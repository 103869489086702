import {
  Alert,
  AlertIcon,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { PropsWithChildren, ReactNode } from "react";
import { usePuzzle } from "../../contexts/PuzzleContext";

export function PuzzleLayout({
  children,
  title,
  description,
  hintTitle,
  hint,
}: PropsWithChildren<{
  title: string;
  description: string;
  hintTitle: string;
  hint: ReactNode;
}>) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { puzzleNumber } = usePuzzle();

  return (
    <VStack spacing="32px" pt={12}>
      <Text align="center" fontSize="5xl">
        <strong>Question {puzzleNumber + 1}</strong> - {title}
      </Text>

      <Text fontSize="lg">{description}</Text>

      {children}

      <Container mt={16}>
        <Alert status="info" pl="12" pr="12">
          <AlertIcon />

          <Text>
            Stuck?{" "}
            <Button
              data-testid="circle-of-fifths-button"
              variant={"link"}
              onClick={onOpen}
            >
              click here
            </Button>{" "}
            to learn more about this puzzle!
          </Text>
        </Alert>
      </Container>

      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">{hintTitle}</DrawerHeader>

          <DrawerBody>{hint}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </VStack>
  );
}
