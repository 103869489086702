"use client";

import { MoonIcon, QuestionIcon, SunIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Heading,
  Image,
  Link,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import blackTrebleClef from "../../assets/black-treble-clef.png";
import whiteTrebleClef from "../../assets/white-treble-clef.png";

export function NavBar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box
      data-testid="nav-bar"
      bg={useColorModeValue("gray.100", "gray.900")}
      px={4}
    >
      <Flex h={20} alignItems={"center"} justifyContent={"space-between"}>
        <Link isExternal href="https://key-master.thesheps.dev">
          <HStack>
            <Image
              maxH={50}
              w={50}
              src={colorMode === "light" ? blackTrebleClef : whiteTrebleClef}
            ></Image>

            <Heading>Key Master</Heading>
          </HStack>
        </Link>

        <Flex alignItems={"center"}>
          <HStack spacing={7}>
            <Button data-testid="circle-of-fifths-button" onClick={onOpen}>
              <QuestionIcon />
            </Button>

            <Button
              data-testid="colour-toggle-button"
              onClick={toggleColorMode}
            >
              {colorMode === "light" ? (
                <MoonIcon data-testid="moon-icon" />
              ) : (
                <SunIcon data-testid="sun-icon" />
              )}
            </Button>
          </HStack>
        </Flex>
      </Flex>

      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">About this site</DrawerHeader>

          <DrawerBody>
            <VStack align={"left"} spacing={4}>
              <Text fontSize="lg">Oh, hello - I'm Sheps! 👋</Text>
              <Text fontSize="lg">
                I spun this little app up over a weekend as a fun challenge to
                help myself get better at learning key signatures and to
                generally be a better musician! It's built using{" "}
                <Link isExternal href="https://react.dev/">
                  React
                </Link>{" "}
                and the{" "}
                <Link isExternal href="https://v2.chakra-ui.com/">
                  ChakraUI
                </Link>{" "}
                css framework which is just unbelievably great. The Circle of
                Fifths implementation is based on the tremendous work of{" "}
                <Link href="https://blog.logrocket.com/author/madsstoumann/">
                  Mads Stoumann
                </Link>
              </Text>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}
