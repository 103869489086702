import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { PuzzleType } from "../common/types";

const PuzzleContext = createContext<
  | {
      puzzleType: PuzzleType;
      puzzleNumber: number;
      setPuzzleType: React.Dispatch<React.SetStateAction<PuzzleType>>;
      setPuzzleNumber: React.Dispatch<React.SetStateAction<number>>;
    }
  | undefined
>(undefined);

export const PuzzleProvider = ({ children }: PropsWithChildren) => {
  const [puzzleType, setPuzzleType] = useState<PuzzleType>("KeySignature");
  const [puzzleNumber, setPuzzleNumber] = useState<number>(0);

  useEffect(() => setPuzzleType("KeySignature"), [puzzleNumber]);

  return (
    <PuzzleContext.Provider
      value={{ puzzleType, puzzleNumber, setPuzzleType, setPuzzleNumber }}
    >
      {children}
    </PuzzleContext.Provider>
  );
};

export const usePuzzle = () => {
  const context = useContext(PuzzleContext);
  if (!context) {
    throw new Error("usePuzzle must be used within a PuzzleProvider");
  }

  return context;
};
