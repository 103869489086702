import { Heading, Text, VStack } from "@chakra-ui/react";

export function SharpRule() {
  return (
    <VStack alignItems={"left"} mt={4} spacing={4}>
      <Heading as="h4" size="md">
        To find the <i>major</i> key
      </Heading>

      <Text>
        Take the last sharp, and count <strong>up</strong> a half-step.
      </Text>

      <Heading as="h4" size="md">
        To find the <i>relative minor</i> key
      </Heading>

      <Text>
        Figure out the major key using the above rules, and then count down a
        minor third.
      </Text>
    </VStack>
  );
}
