import { Box, Link, Text, VStack } from "@chakra-ui/react";

export function Footer() {
  return (
    <Box width="100%" textAlign="center" paddingY="4">
      <VStack>
        <Text>
          Made with 🫶 by{" "}
          <Link isExternal href="https://www.thesheps.dev">
            Sheps
          </Link>
        </Text>
      </VStack>
    </Box>
  );
}
