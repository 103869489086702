import { CheckCircleIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ScaleFade,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import JSConfetti from "js-confetti";
import { PropsWithChildren, ReactElement } from "react";
import { usePuzzle } from "../../contexts/PuzzleContext";
import { getRandomItem } from "../../utils/getRandomItem";

export function ResultModal({
  answer,
  expectedAnswer,
  messages,
}: PropsWithChildren<{
  answer: string;
  expectedAnswer: string;
  messages: ReactElement | ReactElement[];
}>) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { puzzleNumber, setPuzzleNumber } = usePuzzle();

  const isCorrect = answer === expectedAnswer;

  return (
    <>
      <Button
        data-testid="check-answer-button"
        size="lg"
        colorScheme="teal"
        isDisabled={answer === ""}
        leftIcon={<CheckCircleIcon />}
        onClick={() => {
          onOpen();

          if (isCorrect) {
            new JSConfetti().addConfetti({
              emojiSize: 50,
              confettiNumber: 50,
            });
          }
        }}
      >
        Check your answer!
      </Button>

      <Modal data-testid="result-modal" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isCorrect ? "Correct!" : "Try again!"}</ModalHeader>

          <ModalCloseButton />

          {isCorrect && (
            <ModalBody>
              <Text>
                You got it! The correct answer is
                <strong> {`${expectedAnswer}`}.</strong>
              </Text>

              <ScaleFade initialScale={0} in={isOpen}>
                <Box
                  p="12px"
                  color="white"
                  mt="4"
                  bg="teal.500"
                  rounded="md"
                  shadow="md"
                >
                  <Tag size="sm" variant="subtle">
                    <TagLeftIcon boxSize="12px" as={InfoIcon} />

                    <TagLabel>Did you know</TagLabel>
                  </Tag>

                  {Array.isArray(messages) ? getRandomItem(messages) : messages}
                </Box>
              </ScaleFade>
            </ModalBody>
          )}

          <ModalFooter>
            <Button
              data-testid="close-modal-button"
              colorScheme="blue"
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>

            {isCorrect && (
              <Button
                data-testid="next-puzzle-button"
                colorScheme="green"
                onClick={() => setPuzzleNumber(puzzleNumber + 1)}
                mr={3}
              >
                Next Puzzle
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
