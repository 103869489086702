import { HStack, Radio, RadioGroup } from "@chakra-ui/react";
import { Key, MajorKey, MinorKey, majorKey, minorKey } from "@tonaljs/key";
import { Clef } from "abcjs";
import { useEffect, useState } from "react";
import { majorKeys, minorKeys } from "../../../common/constants";
import { getAnswers } from "../../../utils/getAnswers";
import { PuzzleLayout } from "../../layouts/PuzzleLayout";
import { CircleOfFifths } from "../../ui/CircleOfFifths";
import { ResultModal } from "../../ui/ResultModal";
import { Score } from "../../ui/Score";
import { IncidentalCountMessage } from "./IncidentalCountMessage";
import { RelativeKeyMessage } from "./RelativeKeyMessage";

export function KeySignaturePuzzle() {
  const [clef, setClef] = useState<Clef>("treble");
  const [keySignature, setKeySignature] = useState<Key>(majorKeys[0]);
  const [relativeKeySignature, setRelKeySignature] = useState<Key>(
    majorKeys[0],
  );
  const [answers, setAnswers] = useState<Key[]>([]);
  const [answer, setAnswer] = useState<string>("");

  useEffect(() => {
    const isMajor = Math.round(Math.random()) === 0;
    const isTreble = Math.round(Math.random()) === 0;
    const keys = isMajor ? majorKeys : minorKeys;
    const randomKey = keys[Math.floor(Math.random() * keys.length)];
    const answers = getAnswers(randomKey);

    const relative = isMajor
      ? minorKey((randomKey as MajorKey).minorRelative)
      : majorKey((randomKey as MinorKey).relativeMajor);

    setClef(isTreble ? "treble" : "bass");
    setKeySignature(randomKey);
    setRelKeySignature(relative);
    setAnswers(answers);
  }, []);

  const messages = [
    <IncidentalCountMessage keySignature={keySignature} />,
    <RelativeKeyMessage
      keySignature={keySignature}
      relativeKeySignature={relativeKeySignature}
      clef={clef}
    />,
  ];

  return (
    <PuzzleLayout
      title={"What's the key?"}
      description="Pick the correct key signature from the options below!"
      hintTitle="The Circle of Fifths"
      hint={<CircleOfFifths />}
    >
      <Score clef={clef} keySignature={keySignature} />

      <RadioGroup size="lg" onChange={setAnswer} value={answer}>
        <HStack spacing={10}>
          {answers.map((a) => (
            <Radio key={`${a.tonic} ${a.type}`} value={`${a.tonic} ${a.type}`}>
              {`${a.tonic} ${a.type}`}
            </Radio>
          ))}
        </HStack>
      </RadioGroup>

      <HStack>
        <ResultModal
          answer={answer}
          expectedAnswer={`${keySignature.tonic} ${keySignature.type}`}
          messages={messages}
        ></ResultModal>
      </HStack>
    </PuzzleLayout>
  );
}
