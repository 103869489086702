import { Text } from "@chakra-ui/react";
import { Key } from "@tonaljs/key";
import { FlatRule } from "./FlatRule";
import { SharpRule } from "./SharpRule";

export interface IncidentalCountMessageProps {
  keySignature: Key;
}

export function IncidentalCountMessage({
  keySignature,
}: IncidentalCountMessageProps) {
  const sig = keySignature.keySignature;
  const incidental = sig.length === 0 ? "" : sig[0] === "b" ? "flat" : "sharp";

  return (
    <>
      <Text>
        The key of{" "}
        <strong>
          {keySignature.tonic} {keySignature.type}
        </strong>
        {` has `}
        <strong>
          {sig.length === 0
            ? "No incidentals"
            : `${sig.length} ${incidental}${sig.length <= 1 ? "" : "s"}. `}
        </strong>
        {sig.length > 0 && (
          <>
            When trying to figure out a key signature with{" "}
            <strong>{incidental}s</strong>, you can use the following easy
            rules!
          </>
        )}
      </Text>

      {sig.length > 0 && incidental === "sharp" && <SharpRule />}
      {sig.length > 0 && incidental === "flat" && <FlatRule />}
    </>
  );
}
