import heroImage from "../../assets/hero.jpg";
import { LandingLayout } from "../layouts/LandingLayout";
import { Hero } from "../sections/Hero";

export function LandingPage() {
  return (
    <LandingLayout>
      <Hero
        title="Welcome to Key Master!"
        subtitle="Dive into the world of music theory with our captivating game! Learn, play, and master the notes with joy and ease. Start your musical journey today!"
        ctaLink="/puzzles"
        ctaText="Start playing now!"
        image={heroImage}
      />
    </LandingLayout>
  );
}
