import { BrowserRouter, Routes } from "react-router-dom";
import { LandingPage } from "./components/pages/LandingPage";
import { PuzzlesPage } from "./components/pages/PuzzlesPage";
import { getSlideRoute } from "./utils/getSlideRoute";

export function App() {
  return (
    <BrowserRouter>
      <Routes>
        {getSlideRoute("/", <LandingPage />)}
        {getSlideRoute("/puzzles", <PuzzlesPage />)}
      </Routes>
    </BrowserRouter>
  );
}
