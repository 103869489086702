import { Card, CardBody } from "@chakra-ui/react";
import { Key } from "@tonaljs/key";
import { Clef, renderAbc } from "abcjs";
import { PropsWithChildren, useEffect, useState } from "react";
import { uid } from "uid";

export function Score({
  keySignature,
  clef,
}: PropsWithChildren<{
  keySignature: Key;
  clef: Clef;
}>) {
  const [id, setId] = useState("");

  useEffect(() => {
    setId(uid());
  }, []);

  useEffect(() => {
    id &&
      renderAbc(
        id,
        `K:${keySignature.tonic}${keySignature.type} ${clef}\n|\n`,
        { responsive: "resize", staffwidth: 0.5 },
      );
  }, [keySignature, clef, id]);

  return (
    <Card>
      <CardBody w="50vw" maxW="250px">
        <div data-testid="sheet-music" id={id} />
      </CardBody>
    </Card>
  );
}
