import { Key } from "@tonaljs/key";
import { getRandomKey } from "./getRandomKey";
import { shuffleArray } from "./shuffleArray";

export function getAnswers(answer: Key): Key[] {
  const answers = [answer];

  while (answers.length < 3) {
    const randomAnswer = getRandomKey();

    if (!answers.includes(randomAnswer)) {
      answers.push(randomAnswer);
    }
  }

  return shuffleArray(answers);
}
