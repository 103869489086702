import { PropsWithChildren } from "react";
import { Footer } from "../sections/Footer";
import { NavBar } from "../sections/NavBar";

export function LandingLayout({ children }: PropsWithChildren) {
  return (
    <>
      <NavBar />
      {children}
      <Footer />
    </>
  );
}
