import { Slide, SlideDirection } from "@chakra-ui/react";
import { PropsWithChildren, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const SlidePage = ({ children }: PropsWithChildren) => {
  const [direction, setDirection] = useState<SlideDirection>("top");
  const location = useLocation();

  useEffect(() => {
    const lastPath = localStorage.getItem("lastPath");
    if (lastPath && lastPath !== location.pathname) {
      setDirection(
        location.pathname.localeCompare(lastPath) === 1 ? "left" : "right",
      );
    }
    localStorage.setItem("lastPath", location.pathname);
  }, [location]);

  return (
    <Slide direction={direction} in={true} key={location.key}>
      {children}
    </Slide>
  );
};
