import { Box, Button, Flex, Heading, Image, Stack } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

export function Hero({
  title,
  subtitle,
  image,
  ctaLink,
  ctaText,
  ...rest
}: PropsWithChildren<{
  title: string;
  subtitle: string;
  image: string;
  ctaLink: string;
  ctaText: string;
}>) {
  return (
    <Flex
      align="center"
      justify={{
        base: "center",
        md: "space-around",
        xl: "space-between",
      }}
      direction={{ base: "column-reverse", md: "row" }}
      wrap="nowrap"
      minH="70vh"
      px={8}
      mb={16}
      {...rest}
    >
      <Stack
        spacing={4}
        w={{ base: "80%", md: "40%" }}
        align={["center", "center", "flex-start", "flex-start"]}
      >
        <Heading
          as="h1"
          size="xl"
          fontWeight="bold"
          color="primary.800"
          textAlign={["left", "left", "left", "left"]}
        >
          {title}
        </Heading>

        <Heading
          as="h2"
          size="md"
          opacity="0.8"
          fontWeight="normal"
          lineHeight={1.5}
          textAlign={["center", "center", "left", "left"]}
        >
          {subtitle}
        </Heading>

        <Link to={ctaLink}>
          <Button borderRadius="8px" py="4" px="4" lineHeight="1" size="md">
            {ctaText}
          </Button>
        </Link>
      </Stack>

      <Box w={{ base: "80%", sm: "60%", md: "50%" }} mb={{ base: 12, md: 0 }}>
        <Image src={image} rounded="1rem" shadow="2xl" />
      </Box>
    </Flex>
  );
}
