import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { PuzzleProvider } from "./contexts/PuzzleContext";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <PuzzleProvider>
        <App />
      </PuzzleProvider>
    </ChakraProvider>
  </React.StrictMode>,
);

reportWebVitals();
