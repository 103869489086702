import { Synth } from "tone";

import { Center } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import "./CircleOfFifths.css";

export function CircleOfFifths() {
  const [synth, setSynth] = useState<Synth>();
  useEffect(() => setSynth(new Synth().toDestination()), []);

  function playNote(note: string, octave: number) {
    synth && synth.triggerAttackRelease(`${note}${octave}`, "4n");
  }

  return (
    <Center>
      <svg
        style={{ maxWidth: "800px" }}
        id="app"
        className="cf"
        viewBox="0 0 300 300"
      >
        <g className="cf-arcs">
          <path d="M300.00,150.00A150,150,0,0,1,278.58,227.26L235.72,201.50A100,100,0,0,0,250.00,150.00Z"></path>
          <path
            onClick={() => playNote("A", 3)}
            d="M250.00,150.00A100,100,0,0,1,235.72,201.50L210.00,186.05A70,70,0,0,0,220.00,150.00Z"
          ></path>
          <path
            onClick={() => playNote("F#", 3)}
            d="M220.00,150.00A70,70,0,0,1,210.00,186.05L184.29,170.60A40,40,0,0,0,190.00,150.00Z"
          ></path>
        </g>
        <g transform="translate(260, 0)">
          <use width="30" xlinkHref="#staff"></use>

          <use width="2" xlinkHref="#sharp" className="cf-sharp-1"></use>
          <use width="2" xlinkHref="#sharp" className="cf-sharp-2"></use>
          <use width="2" xlinkHref="#sharp" className="cf-sharp-3"></use>
        </g>
        <text x="235" y="153" className="cf-text-middle">
          A
        </text>
        <text x="205" y="152" className="cf-text-inner">
          F<tspan baselineShift="super">#</tspan>m
        </text>

        <g className="cf-arcs">
          <path d="M279.90,225.00A150,150,0,0,1,222.72,281.19L198.48,237.46A100,100,0,0,0,236.60,200.00Z"></path>
          <path
            onClick={() => playNote("E", 4)}
            d="M236.60,200.00A100,100,0,0,1,198.48,237.46L183.94,211.22A70,70,0,0,0,210.62,185.00Z"
          ></path>
          <path
            onClick={() => playNote("C#", 4)}
            d="M210.62,185.00A70,70,0,0,1,183.94,211.22L169.39,184.98A40,40,0,0,0,184.64,170.00Z"
          ></path>
        </g>
        <g transform="translate(243.25317547305485, 62.5)">
          <use width="30" xlinkHref="#staff"></use>

          <use width="2" xlinkHref="#sharp" className="cf-sharp-1"></use>
          <use width="2" xlinkHref="#sharp" className="cf-sharp-2"></use>
          <use width="2" xlinkHref="#sharp" className="cf-sharp-3"></use>
          <use width="2" xlinkHref="#sharp" className="cf-sharp-4"></use>
        </g>
        <text x="223.6121593216773" y="195.5" className="cf-text-middle">
          E
        </text>
        <text x="197.63139720814414" y="179.5" className="cf-text-inner">
          C<tspan baselineShift="super">#</tspan>m
        </text>

        <g className="cf-arcs">
          <path d="M225.00,279.90A150,150,0,0,1,147.38,299.98L148.25,249.98A100,100,0,0,0,200.00,236.60Z"></path>
          <path
            onClick={() => playNote("B", 4)}
            d="M200.00,236.60A100,100,0,0,1,148.25,249.98L148.78,219.99A70,70,0,0,0,185.00,210.62Z"
          ></path>
          <path
            onClick={() => playNote("G#", 4)}
            d="M185.00,210.62A70,70,0,0,1,148.78,219.99L149.30,189.99A40,40,0,0,0,170.00,184.64Z"
          ></path>
        </g>
        <g transform="translate(197.5, 108.25317547305485)">
          <use width="30" xlinkHref="#staff"></use>

          <use width="2" xlinkHref="#sharp" className="cf-sharp-1"></use>
          <use width="2" xlinkHref="#sharp" className="cf-sharp-2"></use>
          <use width="2" xlinkHref="#sharp" className="cf-sharp-3"></use>
          <use width="2" xlinkHref="#sharp" className="cf-sharp-4"></use>
          <use width="2" xlinkHref="#sharp" className="cf-sharp-5"></use>
        </g>
        <text x="192.5" y="226.61215932167727" className="cf-text-middle">
          B
        </text>
        <text x="177.5" y="199.6313972081441" className="cf-text-inner">
          G<tspan baselineShift="super">#</tspan>m
        </text>

        <g className="cf-arcs">
          <path d="M150.00,300.00A150,150,0,0,1,72.744,278.58L98.496,235.72A100,100,0,0,0,150.00,250.00Z"></path>
          <path
            onClick={() => playNote("Gb", 5)}
            d="M150.00,250.00A100,100,0,0,1,98.496,235.72L113.95,210.00A70,70,0,0,0,150.00,220.00Z"
          ></path>
          <path
            onClick={() => playNote("D#", 5)}
            d="M150.00,220.00A70,70,0,0,1,113.95,210.00L129.40,184.29A40,40,0,0,0,150.00,190.00Z"
          ></path>
        </g>
        <g transform="translate(135, 125)">
          <use width="30" xlinkHref="#staff"></use>

          <use width="2" xlinkHref="#sharp" className="cf-sharp-1"></use>
          <use width="2" xlinkHref="#sharp" className="cf-sharp-2"></use>
          <use width="2" xlinkHref="#sharp" className="cf-sharp-3"></use>
          <use width="2" xlinkHref="#sharp" className="cf-sharp-4"></use>
          <use width="2" xlinkHref="#sharp" className="cf-sharp-5"></use>
          <use width="2" xlinkHref="#sharp" className="cf-sharp-6"></use>
        </g>
        <text x="150" y="238" className="cf-text-middle">
          G<tspan baselineShift="super">b</tspan>
        </text>
        <text x="150" y="207" className="cf-text-inner">
          D<tspan baselineShift="super">#</tspan>m
        </text>

        <g className="cf-arcs">
          <path d="M75.000,279.90A150,150,0,0,1,18.807,222.72L62.538,198.48A100,100,0,0,0,100.00,236.60Z"></path>
          <path
            onClick={() => playNote("Db", 6)}
            d="M100.00,236.60A100,100,0,0,1,62.538,198.48L88.777,183.94A70,70,0,0,0,115.00,210.62Z"
          ></path>
          <path
            onClick={() => playNote("Bb", 5)}
            d="M115.00,210.62A70,70,0,0,1,88.777,183.94L115.02,169.39A40,40,0,0,0,130.00,184.64Z"
          ></path>
        </g>
        <g transform="translate(72.50000000000003, 108.25317547305485)">
          <use width="30" xlinkHref="#staff"></use>

          <use width="2" xlinkHref="#flat" className="cf-flat-1"></use>
          <use width="2" xlinkHref="#flat" className="cf-flat-2"></use>
          <use width="2" xlinkHref="#flat" className="cf-flat-3"></use>
          <use width="2" xlinkHref="#flat" className="cf-flat-4"></use>
          <use width="2" xlinkHref="#flat" className="cf-flat-5"></use>
        </g>
        <text
          x="107.50000000000003"
          y="226.6121593216773"
          className="cf-text-middle"
        >
          D<tspan baselineShift="super">b</tspan>
        </text>
        <text
          x="122.50000000000001"
          y="199.63139720814414"
          className="cf-text-inner"
        >
          B<tspan baselineShift="super">b</tspan>m
        </text>

        <g className="cf-arcs">
          <path d="M20.096,225.00A150,150,0,0,1,0.022846,147.38L50.015,148.25A100,100,0,0,0,63.397,200.00Z"></path>
          <path
            onClick={() => playNote("Ab", 7)}
            d="M63.397,200.00A100,100,0,0,1,50.015,148.25L80.011,148.78A70,70,0,0,0,89.378,185.00Z"
          ></path>
          <path
            onClick={() => playNote("F", 7)}
            d="M89.378,185.00A70,70,0,0,1,80.011,148.78L110.01,149.30A40,40,0,0,0,115.36,170.00Z"
          ></path>
        </g>
        <g transform="translate(26.74682452694516, 62.5)">
          <use width="30" xlinkHref="#staff"></use>

          <use width="2" xlinkHref="#flat" className="cf-flat-1"></use>
          <use width="2" xlinkHref="#flat" className="cf-flat-2"></use>
          <use width="2" xlinkHref="#flat" className="cf-flat-3"></use>
          <use width="2" xlinkHref="#flat" className="cf-flat-4"></use>
        </g>
        <text x="76.3878406783227" y="195.5" className="cf-text-middle">
          A<tspan baselineShift="super">b</tspan>
        </text>
        <text x="102.36860279185586" y="179.5" className="cf-text-inner">
          Fm
        </text>

        <g className="cf-arcs">
          <path d="M0.0000,150.00A150,150,0,0,1,21.425,72.744L64.283,98.496A100,100,0,0,0,50.000,150.00Z"></path>
          <path
            onClick={() => playNote("Eb", 8)}
            d="M50.000,150.00A100,100,0,0,1,64.283,98.496L89.998,113.95A70,70,0,0,0,80.000,150.00Z"
          ></path>
          <path
            onClick={() => playNote("C", 8)}
            d="M80.000,150.00A70,70,0,0,1,89.998,113.95L115.71,129.40A40,40,0,0,0,110.00,150.00Z"
          ></path>
        </g>
        <g transform="translate(10, 2.842170943040401e-14)">
          <use width="30" xlinkHref="#staff"></use>

          <use width="2" xlinkHref="#flat" className="cf-flat-1"></use>
          <use width="2" xlinkHref="#flat" className="cf-flat-2"></use>
          <use width="2" xlinkHref="#flat" className="cf-flat-3"></use>
        </g>
        <text x="65" y="153" className="cf-text-middle">
          E<tspan baselineShift="super">b</tspan>
        </text>
        <text x="95" y="152" className="cf-text-inner">
          Cm
        </text>

        <g className="cf-arcs">
          <path d="M20.096,75.000A150,150,0,0,1,77.279,18.807L101.52,62.538A100,100,0,0,0,63.397,100.00Z"></path>
          <path
            onClick={() => playNote("Bb", 8)}
            d="M63.397,100.00A100,100,0,0,1,101.52,62.538L116.06,88.777A70,70,0,0,0,89.378,115.00Z"
          ></path>
          <path
            onClick={() => playNote("G", 9)}
            d="M89.378,115.00A70,70,0,0,1,116.06,88.777L130.61,115.02A40,40,0,0,0,115.36,130.00Z"
          ></path>
        </g>
        <g transform="translate(26.746824526945176, -62.500000000000014)">
          <use width="30" xlinkHref="#staff"></use>

          <use width="2" xlinkHref="#flat" className="cf-flat-1"></use>
          <use width="2" xlinkHref="#flat" className="cf-flat-2"></use>
        </g>
        <text x="76.38784067832272" y="110.5" className="cf-text-middle">
          B<tspan baselineShift="super">b</tspan>
        </text>
        <text x="102.36860279185588" y="124.5" className="cf-text-inner">
          Gm
        </text>

        <g className="cf-arcs">
          <path d="M75.000,20.096A150,150,0,0,1,152.62,0.022846L151.75,50.015A100,100,0,0,0,100.00,63.397Z"></path>
          <path
            onClick={() => playNote("F", 10)}
            d="M100.00,63.397A100,100,0,0,1,151.75,50.015L151.22,80.011A70,70,0,0,0,115.00,89.378Z"
          ></path>
          <path
            onClick={() => playNote("D", 10)}
            d="M115.00,89.378A70,70,0,0,1,151.22,80.011L150.70,110.01A40,40,0,0,0,130.00,115.36Z"
          ></path>
        </g>
        <g transform="translate(72.49999999999994, -108.25317547305481)">
          <use width="30" xlinkHref="#staff"></use>

          <use width="2" xlinkHref="#flat" className="cf-flat-1"></use>
        </g>
        <text
          x="107.49999999999997"
          y="79.38784067832273"
          className="cf-text-middle"
        >
          F
        </text>
        <text
          x="122.49999999999997"
          y="104.36860279185589"
          className="cf-text-inner"
        >
          Dm
        </text>

        <g className="cf-arcs">
          <path d="M150.00,0.0000A150,150,0,0,1,227.26,21.425L201.50,64.283A100,100,0,0,0,150.00,50.000Z"></path>
          <path
            onClick={() => playNote("C", 2)}
            d="M150.00,50.000A100,100,0,0,1,201.50,64.283L186.05,89.998A70,70,0,0,0,150.00,80.000Z"
          ></path>
          <path
            onClick={() => playNote("A", 1)}
            d="M150.00,80.000A70,70,0,0,1,186.05,89.998L170.60,115.71A40,40,0,0,0,150.00,110.00Z"
          ></path>
        </g>
        <g transform="translate(134.99999999999997, -125)">
          <use width="30" xlinkHref="#staff"></use>
        </g>
        <text x="149.99999999999997" y="68" className="cf-text-middle">
          C
        </text>
        <text x="150" y="97" className="cf-text-inner">
          Am
        </text>

        <g className="cf-arcs">
          <path d="M225.00,20.096A150,150,0,0,1,281.19,77.279L237.46,101.52A100,100,0,0,0,200.00,63.397Z"></path>
          <path
            onClick={() => playNote("G", 2)}
            d="M200.00,63.397A100,100,0,0,1,237.46,101.52L211.22,116.06A70,70,0,0,0,185.00,89.378Z"
          ></path>
          <path
            onClick={() => playNote("E", 2)}
            d="M185.00,89.378A70,70,0,0,1,211.22,116.06L184.98,130.61A40,40,0,0,0,170.00,115.36Z"
          ></path>
        </g>
        <g transform="translate(197.5, -108.25317547305482)">
          <use width="30" xlinkHref="#staff"></use>

          <use width="2" xlinkHref="#sharp" className="cf-sharp-1"></use>
        </g>
        <text x="192.5" y="79.38784067832272" className="cf-text-middle">
          G
        </text>
        <text x="177.5" y="104.36860279185588" className="cf-text-inner">
          Em
        </text>

        <g className="cf-arcs">
          <path d="M279.90,75.000A150,150,0,0,1,299.98,152.62L249.98,151.75A100,100,0,0,0,236.60,100.00Z"></path>
          <path
            onClick={() => playNote("D", 3)}
            d="M236.60,100.00A100,100,0,0,1,249.98,151.75L219.99,151.22A70,70,0,0,0,210.62,115.00Z"
          ></path>
          <path
            onClick={() => playNote("B", 2)}
            d="M210.62,115.00A70,70,0,0,1,219.99,151.22L189.99,150.70A40,40,0,0,0,184.64,130.00Z"
          ></path>
        </g>
        <g transform="translate(243.2531754730548, -62.50000000000006)">
          <use width="30" xlinkHref="#staff"></use>

          <use width="2" xlinkHref="#sharp" className="cf-sharp-1"></use>
          <use width="2" xlinkHref="#sharp" className="cf-sharp-2"></use>
        </g>
        <text
          x="223.61215932167727"
          y="110.49999999999997"
          className="cf-text-middle"
        >
          D
        </text>
        <text
          x="197.6313972081441"
          y="124.49999999999997"
          className="cf-text-inner"
        >
          Bm
        </text>
      </svg>

      <svg
        style={{
          position: "absolute",
          width: 0,
          height: 0,
          overflow: "hidden",
        }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <symbol id="staff" viewBox="0 0 203.18 132.08">
            <path
              style={{
                stroke: "var(--light)",
                strokeWidth: "1pt",
                fill: "none",
              }}
              d="m89.253 345.53 177.07.72m-175.63 21.24 177.07.72m-176.35 21.05 177.07.72m-178.87 21.5 177.07.72m-176.35 21.06 177.07.72"
              transform="matrix(1.1144 0 0 1 -96.674 -331.49)"
            />
            <path
              fill="var(--light)"
              transform="matrix(.907 0 0 .91953 -8.112 -4.363)"
              d="M39.542 140.49c.022 8.47 18.816 7.39 18.753-6.57.025-13.61-17.954-89.748-18.155-104.31-.211-11.556 6.122-24.509 11.548-24.368 4.299.141 8.338 9.873 8.316 21.986.326 39.173-33.981 36.641-33.981 63.409 0 17.303 14.363 21.873 21.545 21.873 24.32-.16 20.239-29.381 4.734-29.381-9.141-.328-17.139 12.076-5.06 22.359-20.403-8.977-9.467-30.685 6.692-30.848 21.545.163 25.299 39.658-6.366 39.828-18.933.16-26.931-16.488-26.768-29.546 0-25.135 37.051-39.662 36.561-60.881 0-13.384-13.873-7.345-14.2 14.201.327 13.384 17.628 84.056 17.465 94.996 0 22.52-28.89 15.18-28.89 3.75.163-16.32 16.975-4.73 11.752-4.24-4.081 7.18-3.946 7.74-3.946 7.74z"
            />
            <path
              fill="var(--light)"
              transform="matrix(.907 0 0 .91953 -8.112 -4.363)"
              d="M47.37 133.39c0 3.79-3.161 6.87-7.054 6.87s-7.053-3.08-7.053-6.87 3.16-6.86 7.053-6.86 7.054 3.07 7.054 6.86z"
            />
          </symbol>
          <symbol id="sharp" viewBox="0 0 6 19">
            <path
              fill="var(--light)"
              d="M1.906 12.154V7.45l2-.552v4.68l-2 .576zm3.938-1.138-1.375.394V6.73l1.375-.384V4.402l-1.375.384V.004h-.563v4.927l-2 .575V.856h-.531v4.827L0 6.068v1.948l1.375-.384v4.671L0 12.686v1.94l1.375-.384v4.755h.531v-4.925l2-.55v4.626h.563v-4.8l1.375-.385v-1.947z"
            />
          </symbol>
          <symbol id="flat" viewBox="0 0 .908 2.512">
            <path
              fill="var(--light)"
              d="m.216 1.696-.004.264v.044c0 .088.004.176.016.264.18-.152.372-.32.372-.556 0-.132-.056-.268-.172-.268-.124 0-.208.12-.212.252zm-.168.716L0 .032A.212.212 0 0 1 .108 0c.036 0 .076.012.108.032l-.028 1.38a.577.577 0 0 1 .364-.136c.208 0 .356.192.356.408 0 .32-.344.468-.588.676-.06.052-.096.152-.18.152-.052 0-.092-.044-.092-.1z"
            />
          </symbol>
        </defs>
      </svg>
    </Center>
  );
}
